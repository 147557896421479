var placeSearch, autocomplete;
var componentForm = {
  street_number: 'short_name',
  route: 'long_name',
  locality: 'long_name',
  // administrative_area_level_1: 'short_name',
  country: 'long_name',
  postal_code: 'short_name'
};

function initAutocomplete() {
  // Create the autocomplete object, restricting the search to geographical
  // location types.
  autocomplete = new google.maps.places.Autocomplete(
      /** @type {!HTMLInputElement} */(document.getElementById('autocomplete')),
      {types: ['address']});

  // When the user selects an address from the dropdown, populate the address
  // fields in the form.
  autocomplete.addListener('place_changed', fillInAddress);
}

// [START region_fillform]
function fillInAddress() {
  // Get the place details from the autocomplete object.
  var place = autocomplete.getPlace();

  // Récupération latitude et longitude
  document.getElementById('latitude').value  = place.geometry.location.lat();
  document.getElementById('longitude').value = place.geometry.location.lng();

  console.log(place.geometry.viewport);

  // console.log(place.geometry.location.lat());

  for (var component in componentForm) {
    if(component == 'street_number' || component == 'route')
      component = 'number_route';
    document.getElementById(component).value = '';
    // document.getElementById(component).readonly = true;
  }

  // Get each component of the address from the place details
  // and fill the corresponding field on the form.
  var number_route = '';
  for (var i = 0; i < place.address_components.length; i++) {
    var addressType   = place.address_components[i].types[0];
    if (componentForm[addressType]) {
      var val = place.address_components[i][componentForm[addressType]];
      // Numéro et voie concaténer dans un seul champs
      if(addressType == 'street_number' || addressType == 'route')
        number_route += val+' ';
      else {
        // document.getElementById(addressType).readonly = false;
        document.getElementById(addressType).value = val;
        // document.getElementById(addressType).readonly = true;
      }
    }
  }
  document.getElementById('number_route').value = number_route;
}
// [END region_fillform]

// [START region_geolocation]
// Bias the autocomplete object to the user's geographical location,
// as supplied by the browser's 'navigator.geolocation' object.
function geolocate() {
  console.log('geolocation');
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(function(position) {
      var geolocation = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };
      var circle = new google.maps.Circle({
        center: geolocation,
        radius: position.coords.accuracy
      });
      autocomplete.setBounds(circle.getBounds());
    });
  }
}
// [END region_geolocation]