// var frame = $('.hexagon');
var link = $('.collection--category__link');
var current = $('.collection--category__item.current');

link.hover(over, out);
function over() {
 TweenMax.to($(this).find('.hexagon'), 0.5, {strokeDashoffset: 0, ease:Linear.easeNone});
}
function out() {
 TweenMax.to($(this).find('.hexagon'), 0.3, {strokeDashoffset: 173.153, ease:Linear.easeNone});
}

var tweenFrame = TweenMax.to(current.find('.hexagon'), 0.5, {strokeDashoffset: 0, ease:Linear.easeNone});