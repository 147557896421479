
/*
########   ##     ##  ##    ##    ######    ########   ####   #######   ##    ##   
##         ##     ##  ###   ##   ##    ##      ##       ##   ##     ##  ###   ##   
##         ##     ##  ####  ##   ##            ##       ##   ##     ##  ####  ##   
######     ##     ##  ## ## ##   ##            ##       ##   ##     ##  ## ## ##   
##         ##     ##  ##  ####   ##            ##       ##   ##     ##  ##  ####   
##         ##     ##  ##   ###   ##    ##      ##       ##   ##     ##  ##   ###   
##          #######   ##    ##    ######       ##      ####   #######   ##    ##   
*/


/**
 * Fonction qui analyse un json
 */
var jsonHandler = function(json) {
	if(json.redirect) {
		window.location = json.redirect;
	}
	if(json.message) {
		$('.info')
			.html(json.message)
			.show()
			.removeClass('info--success')
			.removeClass('info--error')
			.addClass('info--'+json.type);

      	demo.spinner.setComplete();
	}
};

/**
 * Function draw svg border
 */
var pathPrepare = function($el) {
    var lineLength = $el[0].getTotalLength();
    $el.css("stroke-dasharray", lineLength);
    $el.css("stroke-dashoffset", lineLength);
};

/**
 * Fonction qui gère les champs obligatoires selon le type d'adhérent (PRO ou PARTICULIER)
 */
var switchTypeAdherent = function() {
	// Si champs type d'adhérent
	if($('#ftype').length > 0) {
		var type = $('#ftype').val();

		// Suppression messages d'erreur
		$(".toggle-required, .toggle-required li").removeClass('error');
		$(".toggle-required .formError").hide();

		// *
		$(".toggle-required span").hide();
		$(".toggle-required[data-type='"+type+"'] span").show();

		// input
		$(".toggle-required input").removeClass("validate[required]");
		$(".toggle-required[data-type='"+type+"'] input").addClass("validate[required]");
	}
};

/**
 * Google map
 */
// function initMapGalerie() {
//     // Basic options for a simple Google Map
//     // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
//     var mapOptions = {
//         // How zoomed in you want the map to start at (always required)
//         zoom: 14,
//         scrollwheel: false,

//         // The latitude and longitude to center the map (always required)
//         center: new google.maps.LatLng(45.7704314, 4.835407300000043), // 44 rue Boson, 38200 Vienne

//         // How you would like to style the map. 
//         // This is where you would paste any style found on Snazzy Maps.
//         styles: [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#9390a6"},{"visibility":"on"}]}]
//     };

//     // Get the HTML DOM element that will contain your map 
//     // We are using a div with id="map" seen below in the <body>
//     var mapElement = document.getElementById('map-galerie');

//     // Create the Google Map using our element and options defined above
//     var map = new google.maps.Map(mapElement, mapOptions);

//     var image = new google.maps.MarkerImage('/images/pointer@2x.png', null, null, null, new google.maps.Size(25,35));

//     // Let's also add a marker while we're at it
//     var marker = new google.maps.Marker({
//         position: new google.maps.LatLng(45.7704314, 4.835407300000043),
//         map: map,
//         icon: image
//     });
// }

/**
 * Google Map [Événement]
 */
var cmsLat      = $('.map-container').attr('data-lat');
var cmsLng      = $('.map-container').attr('data-lng');
var defaultZoom = $('.map-container').attr('data-zoom');

var GoogleMap = function() {

	var stylez = [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#9390a6"},{"visibility":"on"}]}];

	simpleMap = new GMaps( {
		div:              '.map-container',
		lat:              cmsLat,
		lng:              cmsLng,
		zoom:             parseFloat(defaultZoom),
		disableDefaultUI: false,
		scrollwheel:      false//,
		// options: {
		// 	'styles': stylez
		// }
	});

	var image = new google.maps.MarkerImage('/images/pointer@2x.png', null, null, null, new google.maps.Size(25,35));
	
	if( $(".map-container").length > 0 ){
		simpleMap.addMarker({
			lat:  cmsLat,
			lng:  cmsLng,
			icon: image
		});
	}
};

// function initMapEvenement() {
//     // Basic options for a simple Google Map
//     // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
//     var mapOptions = {
//         // How zoomed in you want the map to start at (always required)
//         zoom: 14,
//         scrollwheel: false,

//         // The latitude and longitude to center the map (always required)
//         center: new google.maps.LatLng(45.7704314, 4.835407300000043), // 44 rue Boson, 38200 Vienne

//         // How you would like to style the map. 
//         // This is where you would paste any style found on Snazzy Maps.
//         styles: [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#9390a6"},{"visibility":"on"}]}]
//     };

//     // Get the HTML DOM element that will contain your map 
//     // We are using a div with id="map" seen below in the <body>
//     var mapElement = document.getElementById('map-evenement');

//     // Create the Google Map using our element and options defined above
//     var map = new google.maps.Map(mapElement, mapOptions);

//     var image = new google.maps.MarkerImage('/images/pointer@2x.png', null, null, null, new google.maps.Size(25,35));

//     // Let's also add a marker while we're at it
//     var marker = new google.maps.Marker({
//         position: new google.maps.LatLng(45.7704314, 4.835407300000043),
//         map: map,
//         icon: image
//     });
// }

/**
 * Input search [Header]
 */
new UISearch( document.getElementById( 'sb-search' ) );


/*
########    #######   ##     ##  ########   ########      ###     ########   ##    ##   
##     ##  ##     ##  ###   ###  ##     ##  ##           ## ##    ##     ##   ##  ##    
##     ##  ##     ##  #### ####  ##     ##  ##          ##   ##   ##     ##    ####     
##     ##  ##     ##  ## ### ##  ########   ######     ##     ##  ##     ##     ##      
##     ##  ##     ##  ##     ##  ##   ##    ##         #########  ##     ##     ##      
##     ##  ##     ##  ##     ##  ##    ##   ##         ##     ##  ##     ##     ##      
########    #######   ##     ##  ##     ##  ########   ##     ##  ########      ##      
*/

$(document).ready(function() {

	/*
	 ######     #######    #######   ##    ##   ####  ########   
	##    ##   ##     ##  ##     ##  ##   ##     ##   ##         
	##         ##     ##  ##     ##  ##  ##      ##   ##         
	##         ##     ##  ##     ##  #####       ##   ######     
	##         ##     ##  ##     ##  ##  ##      ##   ##         
	##    ##   ##     ##  ##     ##  ##   ##     ##   ##         
	 ######     #######    #######   ##    ##   ####  ########   
	*/
	$.cookieBar({
		message: "Nous diffusons des cookies afin d'analyser le trafic sur ce site. Les informations concernant l'utilisation que vous faites de notre site nous sont transmises dans cette optique.",
		acceptText: "J'accepte",
		declineButton: true,
		declineText: 'Je refuse',
		expireDays: 30,
		fixed: true,
		zindex:10000
	});

	if(jQuery.cookieBar('cookies')){
		//Google Analytics or other code here
	}

	FastClick.attach(document.body);

	// Link element
	$('[data-link]').click(function() {
		window.location.href = $(this).attr('data-link');
	}).find('a').click(function(e) {
		e.stopPropagation();
	});

	// Link redirection
	$('a[data-redirect]').each(function() {
		var href = this.href;
		setTimeout(function() {
			window.location.href = href;
		}, $(this).attr('data-redirect')*1000);
	});


	/**
	 * Test si les Media Queries sont supportées
	 * Ajoute la classe 'mq' au tag HTML si c'est le cas
	 */
	if ( Modernizr.mq('only all') ) {
		jQuery('html').addClass('mq');
	} else {
		jQuery('html').addClass('no-mq');
	}

	/**
	 * Browser detect : masquage de la bannière
	 */
	$('.browserdetect__close').click(function(e) {
		e.preventDefault();
		$('.browserdetect').addClass('browserdetect--hidden');
	});

	/**
	 * MatchHeight [GRID]
	 */
	$(".harmonize").matchHeight();

	/**
	 * Toggle menu
	 */
	$(".nav-toggle").click(function(e){
		e.preventDefault();
		$(".navigation").toggleClass("open");
		$(".lines-button").toggleClass("close");
	});

	/**
	 * Header style
	 */
	$('body').waypoint(function(direction){
		if (direction === 'down') {
			$('html').removeClass('ontop');
		} else {
			$('html').addClass('ontop');
		}
	}, {offset: '-'+'30px'+'px' });

	/**
	 * Owl Carousel [Slider home]
	 */
	$('.owl-carousel').owlCarousel({
	    loop: true,
	    autoplay: true,
	    autoplayTimeout: 3000,
	    margin: 0,
	    center: true,
	    nav: false,
	    dots: false,
	    items: 1,
	    animateIn: 'fadeIn',
		animateOut: 'fadeOut'
	});

	/**
	 * Input Search [Home]
	 */
	if (!String.prototype.trim) {
		(function() {
			// Make sure we trim BOM and NBSP
			var rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;
			String.prototype.trim = function() {
				return this.replace(rtrim, '');
			};
		})();
	}

	[].slice.call( document.querySelectorAll( 'input.input-search__field' ) ).forEach( function( inputEl ) {
		// in case the input is already filled..
		if( inputEl.value.trim() !== '' ) {
			classie.add( inputEl.parentNode, 'input-search--filled' );
		}

		// events:
		inputEl.addEventListener( 'focus', onInputFocus );
		inputEl.addEventListener( 'blur', onInputBlur );
	} );

	function onInputFocus( ev ) {
		classie.add( ev.target.parentNode, 'input-search--filled' );
	}

	function onInputBlur( ev ) {
		if( ev.target.value.trim() === '' ) {
			classie.remove( ev.target.parentNode, 'input-search--filled' );
		}
	}

	/**
	 * Accordion menu mobile [Filters Collections page]
	 */
	var accordionsMenu = $('.accordion-menu');

	if( accordionsMenu.length > 0 ) {
		
		accordionsMenu.each(function(){
			var accordion = $(this);
			//detect change in the input[type="checkbox"] value
			accordion.on('change', 'input[type="checkbox"]', function(){
				var checkbox = $(this);
				// console.log(checkbox.prop('checked'));
				if(checkbox.prop('checked')) {
					checkbox.siblings('ul').attr('style', 'display:none;').slideDown(300);
				} else {					
					checkbox.siblings('ul').attr('style', 'display:block;').slideUp(300);
				}
				// ( checkbox.prop('checked') ) ?
			});
		});
	}

	/**
	 * Draw svg border [hexagon]
	 */
	var $hexagon_border = $('.collection--category__item:not(.current) .hexagon');
	if ( $hexagon_border.length ) {
	    pathPrepare( $hexagon_border );
	}

	/**
	 * FitVids [Video responsive]
	 */
	$(".video-container").fitVids();

	/**
	 * Google Map : init
	 */
	if ($('#map-galerie').length > 0) {
		initMapGalerie();
	}

	// if ($('#map-evenement').length > 0) {
	// 	initMapEvenement();
	// }

	if ($('.map-container').length > 0) {
		GoogleMap();
	}

	/**
	 * LightGallery
	 */
	$(".lightgallery").lightGallery({
		download: false,
		counter: false
	});

	/**
	 * List photos detail service [Masonry] 
	 */
	$('.owl-carousel--detail').imagesLoaded( function() {
		$('.masonry').masonry({
			itemSelector: '.collection__item--container',
			gutter: 10
		});
	});

	$('.collection-list').imagesLoaded( function() {
		$('.collection-list.masonry').masonry({
			itemSelector: '.collection__item--container',
			gutter: 10
		});
	});

	/**
	 * SHARE
	 */
	$('.share__link--facebook').click(function(e) {
		e.preventDefault();
		var share_url = $(this).attr('data-url');
		var facebook_url = 'http://www.facebook.com/sharer/sharer.php?url=' + share_url;
		window.open(facebook_url, 'facebook_share', 'height=320, width=640, toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, directories=no, status=no');
	});

	$('.share__link--twitter').click(function(e) {
		e.preventDefault();
		var share_url = $(this).attr('data-url');
		var tweet_url = 'https://twitter.com/share?url=' + share_url;
		window.open(tweet_url, 'twitter_share', 'height=320, width=640, toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, directories=no, status=no');
	});


/*
   ###     ########   ##     ##  ########   ########   ########   ##    ##   ########   
  ## ##    ##     ##  ##     ##  ##         ##     ##  ##         ###   ##      ##      
 ##   ##   ##     ##  ##     ##  ##         ##     ##  ##         ####  ##      ##      
##     ##  ##     ##  #########  ######     ########   ######     ## ## ##      ##      
#########  ##     ##  ##     ##  ##         ##   ##    ##         ##  ####      ##      
##     ##  ##     ##  ##     ##  ##         ##    ##   ##         ##   ###      ##      
##     ##  ########   ##     ##  ########   ##     ##  ########   ##    ##      ##      
*/


	$('.service-toggle').click(function(e) {
		e.preventDefault();

		demo.init();

		var data = {
			'action':       'service-toggle',
			'service-type': $(this).attr('data-service-type'),
			'service-id':   $(this).attr('data-service-id')
		};
		var $service = $(this).parents('.fullwidth-list__item');

		$.ajax({
			type:    "POST",
			data:    data,
			url:     window.location.href,
			success: function(retour) {
      			demo.spinner.setComplete();
      			$(".info").removeClass('info--success');
				$service.toggleClass('inactive');
			}
		});
	});

	$('.avatar-edit').click(function(e) {
		e.preventDefault();

		$('#plupload-avatar').removeClass('hidden');
	});


/*
########      ###      ######    ########   ##     ##  ########      ###     ########   ####   #######   ##    ##   
##           ## ##    ##    ##      ##      ##     ##  ##     ##    ## ##       ##       ##   ##     ##  ###   ##   
##          ##   ##   ##            ##      ##     ##  ##     ##   ##   ##      ##       ##   ##     ##  ####  ##   
######     ##     ##  ##            ##      ##     ##  ########   ##     ##     ##       ##   ##     ##  ## ## ##   
##         #########  ##            ##      ##     ##  ##   ##    #########     ##       ##   ##     ##  ##  ####   
##         ##     ##  ##    ##      ##      ##     ##  ##    ##   ##     ##     ##       ##   ##     ##  ##   ###   
##         ##     ##   ######       ##       #######   ##     ##  ##     ##     ##      ####   #######   ##    ##   
*/

	$('.facturation-edit-toggle').click(function(e) {
		e.preventDefault();

		$('.facturation-resume').toggleClass('hidden');
		$('.facturation-edit').toggleClass('hidden');
/*

		var data = {
			'action':       'service-toggle',
			'service-type': $(this).attr('data-service-type'),
			'service-id':   $(this).attr('data-service-id')
		};
		var $service = $(this).parents('.fullwidth-list__item');

		$.ajax({
			type:    "POST",
			data:    data,
			url:     window.location.href,
			success: function(retour) {
      			demo.spinner.setComplete();
				$service.toggleClass('inactive');
			}
		});
*/
	});


/*
##     ##  ########   ########   ####     ###      ######    
###   ###  ##         ##     ##   ##     ## ##    ##    ##   
#### ####  ##         ##     ##   ##    ##   ##   ##         
## ### ##  ######     ##     ##   ##   ##     ##   ######    
##     ##  ##         ##     ##   ##   #########        ##   
##     ##  ##         ##     ##   ##   ##     ##  ##    ##   
##     ##  ########   ########   ####  ##     ##   ######    
*/
	
	$("#service-photo").sortable({
		placeholder: "widget-pict placeholder",
		// handle: ".handle-img",
		start: function(e, ui ){
	    	ui.placeholder.html('<div class="widget-pict-inner"><img src="/images/px.png"></div>');
		}
	});
	
	// Evenement live : si par exemple des nouvelles photos sont uploadées
	$('.widget-container').on('click', '.delete-picture', function(e) {
		e.preventDefault();

		var data     = {
			'action': 'delete',
			'photo':  $(this).attr('data-id')
		};
		var url      = $(this).attr('href');
		var $picture = $(this).parents('.widget-pict');

		$.ajax({
			type:    "POST",
			data:    data,
			url:     url,
			success: function(retour) {
				if(retour) $picture.fadeOut();
			}
		});
	});

	// Evenement live : si par exemple des nouvelles photos sont uploadées
	$('.widget-container').on('click', '.delete-file', function(e) {
		e.preventDefault();

		var data     = {
			'action': 'delete',
			'fichier':  $(this).attr('data-id')
		};
		var url   = $(this).attr('href');
		var $file = $(this).parents('.widget-pict');

		$.ajax({
			type:    "POST",
			data:    data,
			url:     url,
			success: function(retour) {
				if(retour) $file.fadeOut();
			}
		});
	});

	// Gestion légendes fichiers & photos
	$('.widget-container').on('focus', 'input', function(e) {
		$(this).parent().addClass('active');
	});

	$('.widget-container').on('focusout', 'input', function(e) {
		$(this).parent().removeClass('active');

		var data     = {
			'action':  'legende',
			'id':      $(this).attr('data-id'),
			'legende': $(this).val()
		};
		var url      = $(this).attr('data-url');

		$.ajax({
			type:    "POST",
			data:    data,
			url:     url
		});
	});


/*
 ######     #######   ##    ##   ########      ###      ######    ########   
##    ##   ##     ##  ###   ##      ##        ## ##    ##    ##      ##      
##         ##     ##  ####  ##      ##       ##   ##   ##            ##      
##         ##     ##  ## ## ##      ##      ##     ##  ##            ##      
##         ##     ##  ##  ####      ##      #########  ##            ##      
##    ##   ##     ##  ##   ###      ##      ##     ##  ##    ##      ##      
 ######     #######   ##    ##      ##      ##     ##   ######       ##      
*/

	$('.popup-with-form').each(function() {
		$(this).magnificPopup({
		  type:      'inline',
		  preloader: false,
		});

		if($(this).attr('data-open') == 1) {

			$.magnificPopup.open({
			    items: {
			        src: '#fContact'
			    },
			    type: 'inline'
			}, 0);
		}
	});


// console.log($('.open-popup-link').length);
// $('.open-popup-link').magnificPopup({
//   type:'inline',
//   midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
// });

/*
########    #######   ########   ##     ##   ######    
##         ##     ##  ##     ##  ###   ###  ##    ##   
##         ##     ##  ##     ##  #### ####  ##         
######     ##     ##  ########   ## ### ##   ######    
##         ##     ##  ##   ##    ##     ##        ##   
##         ##     ##  ##    ##   ##     ##  ##    ##   
##          #######   ##     ##  ##     ##   ######    
*/
	
	// Changement du type d'adhérent
	switchTypeAdherent();
	$('#ftype').change(switchTypeAdherent);



	/**
	 * Selectric
	 */
	$('.select-multiple').each(function(){
		var placeholder = $(this).data('placeholder');
		var selectAll = $(this).data('selectall');
		$(this).multipleSelect({
			placeholder:        placeholder,
			selectAllText:      "Tout sélectionner",
			allSelected:        selectAll,
			selectAllDelimiter: ['',''],
			countSelected:      "# sur % sélectionnés",
			maxHeight: 300,
			onClick: function(view) {
				view.parent.removeClass('validate[required]');
				$('option[value="'+view.value+'"]', view.parent.prev('select')).attr('selected','selected');
				view.parent.closest('li').removeClass('error');
            }
		});
	});

	// Filtre par localisation
	$("#filter-pays").change(function() {
		$("#filter-ville option").removeAttr('selected');
	});
	$("#filter-pays, #filter-ville").change(function() {
		$('#fLocalisation').submit();
	});


	// Paramétrage validationEngine
	$("form").validationEngine({ 
		scroll: false,
		// showPrompts: false,
		onFieldFailure: function(_input){
			_input.parent('li').addClass('error');
		},
		onFieldSuccess: function(_input){
			_input.parent('li').removeClass('error');
		}
	});

	// Gestion des horaires
	$(".form__horaire-day-close").click(function() {
		if($(this).is(':checked'))
			$(this).parents('.form__horaire-day').find('input[type!=checkbox]').hide();
		else
			$(this).parents('.form__horaire-day').find('input[type!=checkbox]').show();
	});

	// AJAX Submit
	$("form:not('.no-ajax')").submit(function(){
		if($(this).validationEngine('validate')){
			var url  = $(this).attr("action");
			var data = $(this).serialize() + '&' + $("#service-photo").sortable("serialize") + '&act=envoi';

			// console.log($("#service-photo").sortable("serialize"));

			// $("ul.sortable").each(function() {
				// console.log($(this).html());
				// console.log($(this).sortable("serialize"));
			// });

				// data += "&"+$(this).sortable("serialize");
			// console.log(data);
			// return false;


			demo.init();

			$.ajax({
				type    : "POST",
				data    : data,
				url     : url,
				success : function(retour) {
					try {
						var data = JSON.parse(retour);
						jsonHandler(data);
					} catch(ex) {
						console.log("Erreur inopinée");
					}
				}
			});
		}
		return false;
	});

	$('.promo-code__link').click(function(e) {
		e.preventDefault();
		$('#fPromo').toggleClass('hidden');
	});

	$('.promo-code__delete').click(function(e) {
		e.preventDefault();
		var url = $(this).attr("href");
		var data = {
			"action": "delete-promotion"
		};

		demo.init();

		$.ajax({
			type    : "POST",
			data    : data,
			url     : url,
			success : function(retour) {
				try {
					var data = JSON.parse(retour);
					jsonHandler(data);
				} catch(ex) {
					console.log("Erreur inopinée");
				}
			}
		});
	});
});
