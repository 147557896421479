$(".upload-zone").each(function() {
    var url            = $(this).attr('data-url');
    var type           = $(this).attr('data-type');
    var multiselection = true;
    var $plupload      = $('#plupload-'+type);
    
    if(type == 'fichier') {
        var dossier_nom = $(this).attr('data-dossier-nom');
        var dossier_id  = $(this).attr('data-dossier-id');
        var params      = {
            "dossier_id":  dossier_id,
            "dossier_nom": dossier_nom
        };
        var filters = [{title : "Fichiers", extensions : "pdf,doc"}];
    } else {
        var album_nom = $(this).attr('data-album-nom');
        var album_id  = $(this).attr('data-album-id');
        var params    = {
            "album_id":  album_id,
            "album_nom": album_nom
        };
        var filters = [{title : "Images", extensions : "jpg,jpeg,gif,png,JPG"}];
        if(type == 'avatar') multiselection = false;
    }
	
    var uploader = new plupload.Uploader({
		// OJ
		button_browse_hover: true,
		autostart:           true,
		drop_element:        'plupload-'+type,
		browse_button:       'plupload-'+type,

		// General settings
		runtimes:        'html5,flash,html4', //,silverlight,browserplus,html5,gears',
		url:             url,
		max_file_size:   '10mb',
		chunk_size:      '1mb',

        // Post variables
        multipart_params: params,
        multi_selection:  multiselection,

		// Resize images on clientside if we can
		// resize : {width : 320, height : 240, quality : 90},

		// Specify what files to browse for
		filters: filters,

		// Flash settings
		flash_swf_url: 'include/plupload/js/plupload.flash.swf'

	});
	uploader.init();

    // Ajout des fichiers à la liste d'attente
	uploader.bind('FilesAdded', function(uploader, files, Response) {
        var renderString = [];
        $.each(files, function(i, file) {
            renderString.push('<div id="'+type+'-'+file.id+'" class="fileItem">');
            renderString.push('	<span class="name">'+file.name+'</span>');
            renderString.push('	<span class="size">'+plupload.formatSize(file.size)+'</span>');
            if(type == 'avatar')
                renderString.push(' <div class="sidebar-profil__avatar plupload_progress"><div class="plupload_progress_bar"></div><span class="plupload_working"></span></div>');
            else
                renderString.push(' <div class="plupload_progress"><div class="plupload_progress_bar"></div></div>');       
            renderString.push(' <span class="spinner-container"></span>');
            renderString.push('</div>');
        });
        if($plupload.find('.upload-feedback').length > 0) {
        	$plupload.find('.upload-feedback').append(renderString.join(''));
        } else {
        	$plupload.append('<div class="upload-feedback">'+renderString.join('')+'</div>');
        }
        uploader.refresh();
        uploader.start();
    });

    // Progression
    uploader.bind('UploadProgress', function(up, file) {
        $(this).removeClass("hover");
        var $fileWrapper = $('#'+type+'-'+file.id);
        $fileWrapper.find(".plupload_progress").show();
       
        if(type == 'avatar')
           $fileWrapper.find(".plupload_progress_bar").attr("style", "height:"+ file.percent + "%");
        else
           $fileWrapper.find(".plupload_progress_bar").attr("style", "width:"+ file.percent + "%");

        if(file.percent >= 99) {
            if(type == 'avatar')
                $fileWrapper.find(".plupload_progress").append('<span class="plupload_working no-spinner"></span>');
            else
        	   $fileWrapper.find(".plupload_progress").html("Finalisation en cours");
        }
    });

    // Fin de l'upload d'un fichier
    uploader.bind('FileUploaded', function(up, file, Response) {
        var $fileItem = $('#'+file.id);
        if(type == 'avatar'){
            $(".upload-zone--avatar").css("background-image","url("+Response.response+")");
        } else {
            $("#service-"+type).append(Response.response);
        }
        $fileItem.fadeOut('fast');
        $plupload.removeClass('hover');
    });

    uploader.bind('UploadComplete', function(up, file, Response) {
    	$plupload.find('.upload-feedback').remove();
    });

    $plupload.bind('dragover drop', function(e) {
        $(this).addClass('hover');
        e.preventDefault();
    }).bind('dragexit', function(e) {
        $(this).removeClass('hover');
        e.preventDefault();
    });
});